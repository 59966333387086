import { MenuEntry, menuStatus } from 'blazeswap-uikit'

const config: MenuEntry[] = [
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    href: '/swap',
  },
  {
    label: 'Liquidity',
    icon: 'InfoIcon',
    href: '/pool',
  },
  {
    label: "Farms",
    icon: "FarmIcon",
    href: "/farm",
    status: menuStatus.SOON,
  },
]

export default config
